
import { defineComponent } from "vue";
import { apiService, Category } from "./apiService";
export default defineComponent({
  data() {
    return {
      categories: [] as Category[],
    };
  },
  mounted() {
    if (window.innerWidth > 800) {
      this.getCategories();
    }
  },
  methods: {
    async getCategories() {
      this.categories = await apiService.GetCategories();
    },
    goToCategory(id: number) {
      this.$router.push("/category/" + id);
    },
  },
});
