// auth.ts
export default {
  // Implement methods to set and check the user's authentication state
  isLoggedIn() {
    if (sessionStorage.getItem("token")) {
      // console.log("true");
      return true;
    } else {
      // console.log("false");
      return false;
    }
  },
};
