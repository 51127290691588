
import { Options, Vue } from "vue-class-component";
import NavbarComponent from "./components/NavbarComponent.vue";
import FooterComponent from "./components/FooterComponent.vue";

@Options({
  components: {
    NavbarComponent,
    FooterComponent,
  },
})
export default class App extends Vue {
  mounted(): void {
    document.addEventListener("DOMContentLoaded", function () {
      // Your code here
      document
        .getElementById("loadingScreenContainer")
        ?.classList.add("invisible");
    });
  }
}
