
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    imageUrl: {
      type: String as PropType<string>,
      required: true,
    },
    name: {
      type: String as PropType<string>,
      required: true,
    },
    text: {
      type: String as PropType<string>,
      required: true,
    },
    url: {
      type: String as PropType<string>,
      required: true,
    },
    postId: {
      type: String as PropType<string>,
      required: true,
    },
  },
  mounted() {
    // console.log("Received message prop:", this.imageUrl);
    if (this.imageUrl) {
      if (this.imageUrl.length < 100) {
        document
          .querySelector("#showcase-img-" + this.postId)
          ?.classList.add("invisible");
      }
    }
  },
});
