
import { defineComponent } from "vue";
import FeaturedData from "../components/FeaturedData.vue";
import {
  CarouselPostsData,
  apiService,
  Post,
  Filter,
} from "@/components/apiService";

export default defineComponent({
  data() {
    return {
      searchData: {} as Filter,
      currentSearchData: {} as Filter,
      carouselPosts: {} as CarouselPostsData,
      searchResults: [] as Post[],
      currentPage: 1,
      totalPages: 1,
      pageSize: 10,
    };
  },
  components: {
    "featured-data": FeaturedData,
  },
  methods: {
    async performSearch() {
      // Implement your search logic here, e.g., make an API request
      this.currentSearchData = this.searchData;
      const response = await apiService.GetPosts(
        1,
        this.pageSize,
        this.searchData
      );
      this.totalPages = Math.ceil(response.totalCount / this.pageSize);
      this.searchResults = response.data;

      document
        .querySelector(".featured-full-container")
        ?.classList.add("invisible");
      if (this.searchResults.length < 1) {
        document
          .querySelector(".no-results-container")
          ?.classList.remove("invisible");
      } else {
        document
          .querySelector(".search-results-container")
          ?.classList.remove("invisible");
      }
    },
    handleInputChange() {
      // You can perform live search as the user types if desired
      // Implement your search logic here, e.g., debounce API requests
    },
    handleEnter(event: KeyboardEvent) {
      if (event.key === "Enter") {
        event.preventDefault();
        this.performSearch();
      }
    },
    async getCarousel() {
      this.carouselPosts = await apiService.getCarouselPosts();
    },
    async goToPost(id: number) {
      // await apiService.getPostById(id);
      this.$router.push("/posts/" + id);
    },
    async prevPage() {
      if (this.currentPage >= 1) {
        this.currentPage--;
        const response = await apiService.GetPosts(
          this.currentPage,
          this.pageSize,
          this.currentSearchData
        );
        this.totalPages = Math.ceil(response.totalCount / this.pageSize);
        this.searchResults = response.data;
      }
    },
    async nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        const response = await apiService.GetPosts(
          this.currentPage,
          this.pageSize,
          this.currentSearchData
        );
        this.totalPages = Math.ceil(response.totalCount / this.pageSize);
        this.searchResults = response.data;
      }
    },
  },
  mounted() {
    document.addEventListener("keypress", this.handleEnter);
    this.getCarousel();
    apiService.ChooseLang();
    const nameInput = document.getElementById(
      "searchNameInput"
    )! as HTMLInputElement;

    const infoInput = document.getElementById(
      "searchInfoInput"
    )! as HTMLInputElement;

    if (localStorage.getItem("lang") == "en") {
      nameInput.placeholder = "Name";
      infoInput.placeholder = "Additional Info";
    } else {
      nameInput.placeholder = "სახელი";
      infoInput.placeholder = "დამატებითი ინფორმაცია";
    }
  },
  beforeUnmount() {
    document.removeEventListener("keypress", this.handleEnter);
  },
});
