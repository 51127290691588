
import { apiService, Post } from "@/components/apiService";
import { defineComponent, ref, onMounted } from "vue";
import { RouteLocationNormalizedLoaded } from "vue-router";
import SideBar from "@/components/SideBar.vue";

export default defineComponent({
  data() {
    return {
      postId: null as number | null,
      postData: {} as Post,
      videoEmbed: "",
    };
  },
  components: {
    "side-bar": SideBar,
  },
  methods: {
    fetchPostDetails() {
      // Fetch post details using this.postId
      // You can use an API call here or load data in another way
    },
    async goToCategory(id: number) {
      // await apiService.getPostById(id);
      this.$router.push("/category/" + id);
    },
  },
  watch: {
    $route(
      to: RouteLocationNormalizedLoaded,
      from: RouteLocationNormalizedLoaded
    ) {
      const id = to.params.id;
      if (id) {
        // Check if id is a string or an array of strings
        if (Array.isArray(id)) {
          // Handle the case where id is an array of strings
          this.postId = parseInt(id[0]);
        } else {
          // Handle the case where id is a single string
          this.postId = parseInt(id);
        }

        this.fetchPostDetails();
      }
    },
  },
  async mounted() {
    this.fetchPostDetails();
    const postID = window.location.href.toString().split("/")[
      window.location.href.toString().split("/").length - 1
    ];
    // console.log(
    //   window.location.href.toString().split("/")[
    //     window.location.href.toString().split("/").length - 1
    //   ]
    // );
    this.postData = await apiService.getPostById(parseInt(postID));
    if (this.postData.videoLink) {
      if (this.postData.videoLink!.length > 10) {
        if (this.postData.videoLink?.includes("facebook")) {
          this.videoEmbed =
            "https://www.facebook.com/plugins/video.php?href=" +
            this.postData.videoLink! +
            "&width=640&height=360";
          document
            .querySelector(".video-display-container")
            ?.classList.remove("invisible");
        } else if (this.postData.videoLink?.includes("youtube")) {
          this.videoEmbed =
            "https://www.youtube.com/embed/" +
            this.postData.videoLink.split("watch?v=")[1];
          if (this.videoEmbed.includes("&")) {
            this.videoEmbed = this.videoEmbed.split("&")[0];
          }
          document
            .querySelector(".video-display-container")
            ?.classList.remove("invisible");
        }
      }
    }
    if (this.postData.photo) {
      if (this.postData.photo.length < 100) {
        document.querySelector(".person-img")?.classList.add("invisible");
      }
    }
    apiService.ChooseLang();
  },
});
