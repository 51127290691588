import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import PostDetail from "../views/PostDetail.vue";
import SearchResults from "../views/SearchResults.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LoginView.vue"),
  },
  {
    path: "/adminPanel",
    name: "adminPanel",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AdminView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/adminPage",
    name: "adminPage",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AdminPageView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/search",
    name: "search",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SearchView.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ContactView.vue"),
  },
  {
    path: "/finance",
    name: "finance",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/FinanceView.vue"),
  },
  {
    path: "/posts/:id", // Dynamic route parameter ":id"
    name: "PostDetail",
    component: PostDetail,
  },
  {
    path: "/category/:id", // Dynamic route parameter ":id"
    name: "SearchResults",
    component: SearchResults,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

import auth from "./auth";
import { API_TOKEN } from "../components/config";

router.beforeEach((to, from, next) => {
  to.meta.token = API_TOKEN;
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!auth.isLoggedIn()) {
      // User is not logged in, redirect to the login page
      next("/login");
    } else {
      // User is authenticated, proceed to the protected route
      next();
    }
  } else {
    // Allow access to non-protected routes
    next();
  }
});

export default router;
