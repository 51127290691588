/* eslint-disable prettier/prettier */
/* eslint-disable no-useless-catch */
// apiService.ts
import axios from "axios";
import { BASE_URL } from "./config";
import queryString from 'query-string';


class ApiService {
  private readonly baseURL: string;
  private authToken = sessionStorage.getItem("token");
  private config: object;
  private config2: object;

  constructor() {
    // Replace 'your-base-url' with your API base URL
    this.baseURL = BASE_URL;
    // Request configuration
    this.config = {
      headers: {
        Authorization: `Bearer ${this.authToken}`, // Bearer token header
      },
    }

    this.config2 = {
      headers: {
        Authorization: `Bearer ${this.authToken}`, // Bearer token header
        'Content-Type': 'multipart/form-data',
      },
    }

    document.addEventListener("DOMContentLoaded", () => {
      if (localStorage.getItem("lang") == null) {
        this.ChooseLang("ka");
      }else {
        this.ChooseLang(localStorage.getItem("lang")!);
      }
    });
  
  }
  
  public ChooseLang(langCode?: string, reloadPage?: boolean) {
    if(langCode){
      if (langCode == "en") {
        localStorage.setItem("lang", "en");
        if(reloadPage) {
          location.reload();
        }
        document.getElementById("lang1")?.classList.add("active-lang");
        document.getElementById("lang2")?.classList.remove("active-lang");
        document.querySelectorAll(".langKA").forEach(e => {
          e.classList.add("langHidden");
        });
        document.querySelectorAll(".langEN").forEach(e => {
          e.classList.remove("langHidden");
        });
      } else if (langCode == "ka") {
        localStorage.setItem("lang", "ka");
        if(reloadPage) {
          location.reload();
        }
        document.getElementById("lang1")?.classList.remove("active-lang");
        document.getElementById("lang2")?.classList.add("active-lang");
        document.querySelectorAll(".langEN").forEach(e => {
          e.classList.add("langHidden");
        });
        document.querySelectorAll(".langKA").forEach(e => {
          e.classList.remove("langHidden");
        });
      }
    }else {
      this.ChooseLang(localStorage.getItem("lang")!);
    }
  }

  async login(username: string, password: string): Promise<any> {
    this.ShowLoadingScreen();
    const url = `${this.baseURL}Auth/Login?username=${username}&password=${password}`;
    const response = await axios.post(url);
    this.HideLoadingScreen();
    if (response.data.success) {
      if (response.data.message.toLowerCase().includes("code sent")) {
        return "2Auth";
      } else {
        this.authToken = response.data.data;
        return response.data.data;
      }
    } else {
      alert("Authorisation Unsuccessful - "+ response.data.message);
      return "NoAuth";
    }
  }
 
  async login2FA(username: string, code: number): Promise<any> {
    this.ShowLoadingScreen();
    const url = `${this.baseURL}Auth/Login2FA?username=${username}&code=${code}`;
    const response = await axios.post(url);
    this.HideLoadingScreen();
    if (response.data.success) {
      this.authToken = response.data.data;
      return response.data.data;
    } else {
      alert("Authorisation Unsuccessful - "+ response.data.message);
      return "NoAuth";
    }
  }

  async registerUser(userData: UserCredentials): Promise<any> {
    this.ShowLoadingScreen();
    const requestUrl = `${this.baseURL}Auth/Register?${queryString.stringify(userData)}`;
    const response = await axios.post(requestUrl,null,this.config);
    this.HideLoadingScreen();
    return response.data.success;
  }

  async changePassword(passData: PasswordData): Promise<any> {
    this.ShowLoadingScreen();
    const requestUrl = `${this.baseURL}Auth/ChangePassword?${queryString.stringify(passData)}`;
    const response = await axios.post(requestUrl,null,this.config);
    this.HideLoadingScreen();
    return response.data.success;
  }

  async UpdateMail(mailToUpdate: string): Promise<boolean> {
    this.ShowLoadingScreen();
    const requestUrl = `${this.baseURL}Auth/UpdateMail?${queryString.stringify({ mail: mailToUpdate})}`;
    const response = await axios.post(requestUrl,null,this.config);
    this.HideLoadingScreen();
    if(!response.data.success){
      alert(response.data.message);
    }
    return response.data.success;
  }

  async ValidateMail(codeToValidate: number): Promise<boolean> {
    this.ShowLoadingScreen();
    const requestUrl = `${this.baseURL}Auth/ValidateMail?${queryString.stringify({ code: codeToValidate})}`;
    const response = await axios.post(requestUrl,null,this.config);
    this.HideLoadingScreen();
    if(!response.data.success){
      alert(response.data.message);
    }
    return response.data.success;
  }

  async TurnOn2Factor(): Promise<boolean> {
    this.ShowLoadingScreen();
    const requestUrl = `${this.baseURL}Auth/TurnOn2FA`;
    const response = await axios.post(requestUrl,null,this.config);
    this.HideLoadingScreen();
    if(!response.data.success){
      alert(response.data.message);
    }
    return response.data.success;
  }

  async TurnOff2Factor(): Promise<boolean> {
    this.ShowLoadingScreen();
    const requestUrl = `${this.baseURL}Auth/TurnOff2FA`;
    const response = await axios.post(requestUrl,null,this.config);
    this.HideLoadingScreen();
    if(!response.data.success){
      alert(response.data.message);
    }
    return response.data.success;
  }

  async AcceptTurnOn2FA(codeToValidate2Factor: number): Promise<boolean> {
    this.ShowLoadingScreen();
    const requestUrl = `${this.baseURL}Auth/AcceptTurnOn2FA?${queryString.stringify({ code: codeToValidate2Factor})}`;
    const response = await axios.post(requestUrl,null,this.config);
    this.HideLoadingScreen();
    if(!response.data.success){
      alert(response.data.message);
    }
    return response.data.success;
  }

  

  
  async SendRecommendedPost(recommendedPostData: RecommendedPost): Promise<boolean> {
    this.ShowLoadingScreen();
    const requestUrl = `${this.baseURL}Contact/SendRecommendedPost?${queryString.stringify(recommendedPostData)}`;
    const response = await axios.post(requestUrl,null,this.config);
    this.HideLoadingScreen();
    return response.data.success;
  }


  async GetCategories(): Promise<Category[]> {
    this.ShowLoadingScreen();
    // const requestUrl = `${this.baseURL}Category/GetAllCategories/{culture}?${queryString.stringify({culture: localStorage.getItem("lang")})}`;
    const requestUrl = `${this.baseURL}Category/GetAllCategories/${localStorage.getItem("lang")}`;
    const response = await axios.get(requestUrl,this.config);
    this.HideLoadingScreen();
    return response.data.data;
  }

  async AddSubCategory(subCategoryData: SubCategoryAddition): Promise<any> {
    this.ShowLoadingScreen();
    const requestUrl = `${this.baseURL}Category/AddSubCategory?${queryString.stringify(subCategoryData)}`;
    const response = await axios.post(requestUrl,null,this.config);
    this.HideLoadingScreen();
    if(response.data.success) {
      location.reload();
    };
  }
  async EditSubCategory(subCategoryData: SubCategoryEdit): Promise<any> {
    this.ShowLoadingScreen();
    const requestUrl = `${this.baseURL}Category/UpdateSubCategory?${queryString.stringify(subCategoryData)}`;
    const response = await axios.post(requestUrl,null,this.config);
    this.HideLoadingScreen();
    if(response.data.success) {
      location.reload();
    };
  }
  async DeleteSubCategory(catId: number): Promise<any> {
    this.ShowLoadingScreen();
    const requestUrl = `${this.baseURL}Category/DeleteSubCategory?${queryString.stringify({catId: catId})}`;
    const response = await axios.post(requestUrl,null,this.config);
    this.HideLoadingScreen();
    if(response.data.success) {
      location.reload();
    };
  }
  // Add methods for other API calls as needed

  async getCarouselPosts(): Promise<CarouselPostsData> {
    this.ShowLoadingScreen();
    const response = await axios.get(`${this.baseURL}Posts/GetCarouselPosts?${queryString.stringify({culture: localStorage.getItem("lang")})}`);
    this.HideLoadingScreen();
    return response.data.data;
  }

  async getPostById(id: number): Promise<Post> {
    this.ShowLoadingScreen();
    const response = await axios.get(`${this.baseURL}Posts/GetPostById/${id}/${localStorage.getItem("lang")}`);
    this.HideLoadingScreen();
    return response.data.data;
  }

  async getPinnedPosts(): Promise<Post[]> {
    this.ShowLoadingScreen();
    const response = await axios.get(`${this.baseURL}Posts/GetPinnetPosts`,this.config);
    this.HideLoadingScreen();
    return response.data.data;
  }
  // You can add more methods for other API endpoints


  async GetPosts(pageIndex: number, pageSize: number, filter: Filter ): Promise<ReceivedPosts> {
    // Filter out properties with undefined or null values
    this.ShowLoadingScreen();;
    const requestUrl = `${this.baseURL}Posts/GetPosts?` + queryString.stringify({page: pageIndex, size: pageSize, ...filter, culture: localStorage.getItem("lang") });
    const response = await axios.get(requestUrl,this.config);
    this.HideLoadingScreen();
    if(response.data.success) {
      return response.data.data;
    } else {
      console.error("could not get posts");
      return {totalCount:0, data:[]};
    }
  }
  async AddNewPost(categoryData: NewPost): Promise<any> {
    this.ShowLoadingScreen();
    const formData = new FormData();
    if(categoryData.photo){
      formData.append("Photo",categoryData.photo);
      categoryData.photo = new Blob();
    }
    formData.append("Content",categoryData.Content!);
    formData.append("ContentEng",categoryData.ContentEng!);
    categoryData.Content = "";
    categoryData.ContentEng = "";
    const requestUrl = `${this.baseURL}Posts/AddNewPost?` + queryString.stringify(categoryData);
    const response = await axios.post(requestUrl,formData,this.config2);
    this.HideLoadingScreen();
    if(response.data.success) {
      location.reload();
    };
  }
  async AddNewPostByPerson(categoryData: NewPost): Promise<any> {
    this.ShowLoadingScreen();
    const formData = new FormData();
    if(categoryData.photo){
      formData.append("Photo",categoryData.photo);
    }
    formData.append("Content",categoryData.Content!);
    formData.append("ContentEng",categoryData.ContentEng!);
    categoryData.Content = "";
    categoryData.ContentEng = "";
    const requestUrl = `${this.baseURL}Posts/AddNewPostToPerson?` + queryString.stringify(categoryData);
    const response = await axios.post(requestUrl,formData,this.config2);
    this.HideLoadingScreen();
    if(response.data.success) {
      location.reload();
    };
  }
  async DeletePost(postID: number): Promise<any> {
    this.ShowLoadingScreen();
    const requestUrl = `${this.baseURL}Posts/DeletePost?` + queryString.stringify({ id: postID });
    const response = await axios.post(requestUrl,null,this.config);
    this.HideLoadingScreen();
    if(response.data.success) {
      location.reload();
    };
  }
  async EditPost(categoryData: NewPost): Promise<any> {
    const formData = new FormData();
    if(categoryData.photo){
      formData.append("Photo",categoryData.photo);
    }
    formData.append("Content",categoryData.Content!);
    formData.append("ContentEng",categoryData.ContentEng!);
    categoryData.Content = "";
    categoryData.ContentEng = "";
    this.ShowLoadingScreen();
    const requestUrl = `${this.baseURL}Posts/EditPost?` + queryString.stringify(categoryData);
    const response = await axios.post(requestUrl,formData,this.config);
    this.HideLoadingScreen();
    if(response.data.success) {
      location.reload();
    };
  }
  async PinPost(postID: number): Promise<any> {
    this.ShowLoadingScreen();
    const requestUrl = `${this.baseURL}Posts/PinPost?` + queryString.stringify({ postId: postID });
    const response = await axios.post(requestUrl,null,this.config);
    this.HideLoadingScreen();
    if(response.data.success) {
      location.reload();
    };
  }
  async UnpinPost(postID: number): Promise<any> {
    this.ShowLoadingScreen();
    const requestUrl = `${this.baseURL}Posts/UnPinPost?` + queryString.stringify({ postId: postID });
    const response = await axios.post(requestUrl,null,this.config);
    this.HideLoadingScreen();
    if(response.data.success) {
      location.reload();
    };
  }
  private ShowLoadingScreen() {
    document
    .getElementById("loadingScreenContainer")
    ?.classList.remove("invisible");
  }
  private HideLoadingScreen() {
    document
    .getElementById("loadingScreenContainer")
    ?.classList.add("invisible");
  }
}

// Export an instance of the ApiService class
export const apiService = new ApiService();

export interface Post {
  id: number;
  title: string;
  content: string;
  personId: number;
  personName: string;
  categories: Category[]; // Assuming categories can be an array of strings or null
  videoLink?: string;
  createDate: string; // This assumes createDate is in ISO 8601 format as shown in your example
  photo?: string;
}
export interface ReceivedPosts {
  totalCount: number;
  data: Post[];
}
export interface Category {
  id: number;
  name: string;
  subCategories: Category[] | null;
}

export interface SubCategoryAddition {
  categoryName?: string;
  description?: string;
  personCatId?: number;
  categoryNameEng?: string;
}
export interface SubCategoryEdit {
  Name?: string;
  NameEng?: string;
  Id: number;
}

export interface RecommendedPost {
  body: string;
  clientPhone: string;
  clientContact: string; // You can specify a more specific type for clientContact if needed
}

export interface CarouselPostsData {
  latest: Post[];
  random: Post[];
  recommended: Post[];
}

export interface Filter {
  DateFrom? : JSON | unknown, // this is the JavaScript date as a c# DateTime
  DateTo? : JSON | unknown,
  Keyword? : string,
  PersonName? : string,
  CategoryId? : number,
}

export interface NewPost {
  Id?: number;
  PersonId?: number;
  Content?: string;
  ContentEng?: string;
  Title?: string;
  TitleEng?: string;
  PersonName?: string;
  PersonNameEng?: string;
  VideoLink?: string;
  TagIds?: number[];
  photo?: Blob;
}

export interface UserCredentials {
  username: "",
  password: "",
}

export interface PasswordData {
  oldPassword: "",
  newPassword: "",
}