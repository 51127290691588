
import SideBar from "@/components/SideBar.vue";
import { apiService, Post } from "@/components/apiService";
import { defineComponent, ref, onMounted } from "vue";
import { RouteLocationNormalizedLoaded } from "vue-router";

export default defineComponent({
  data() {
    return {
      postId: null as number | null,
      searchResults: [] as Post[],
      currentPage: 1,
      totalPages: 1,
      pageSize: 10,
      postID: "",
    };
  },
  components: {
    "side-bar": SideBar,
  },
  methods: {
    fetchPostDetails() {
      // Fetch post details using this.postId
      // You can use an API call here or load data in another way
    },
    async goToPost(id: number) {
      // await apiService.getPostById(id);
      this.$router.push("/posts/" + id);
    },
    async prevPage() {
      if (this.currentPage >= 1) {
        this.currentPage--;
        const response = await apiService.GetPosts(
          this.currentPage,
          this.pageSize,
          {
            CategoryId: parseInt(this.postID),
          }
        );
        this.totalPages = Math.ceil(response.totalCount / this.pageSize);
        this.searchResults = response.data;
      }
    },
    async nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        const response = await apiService.GetPosts(
          this.currentPage,
          this.pageSize,
          {
            CategoryId: parseInt(this.postID),
          }
        );
        this.totalPages = Math.ceil(response.totalCount / this.pageSize);
        this.searchResults = response.data;
      }
    },
    async getSearchResults() {
      const response = await apiService.GetPosts(
        this.currentPage,
        this.pageSize,
        {
          CategoryId: parseInt(this.postID),
        }
      );
      this.searchResults = response.data;
      this.totalPages = response.totalCount;
    },
  },
  watch: {
    $route(
      to: RouteLocationNormalizedLoaded,
      from: RouteLocationNormalizedLoaded
    ) {
      if (
        to.params.id !== this.postID &&
        from.fullPath.includes("/category/") &&
        to.fullPath.includes("/category/")
      ) {
        // console.log("to.params.id as string", to.params.id as string);
        this.postID = to.params.id as string;
        this.getSearchResults();
      }
      // const id = to.params.id;
      // if (id) {
      //   // Check if id is a string or an array of strings
      //   if (Array.isArray(id)) {
      //     // Handle the case where id is an array of strings
      //     this.postId = parseInt(id[0]);
      //   } else {
      //     // Handle the case where id is a single string
      //     this.postId = parseInt(id);
      //   }

      //   this.fetchPostDetails();
      // }
    },
  },
  async mounted() {
    this.fetchPostDetails();
    this.postID = window.location.href.toString().split("/")[
      window.location.href.toString().split("/").length - 1
    ];
    const response = await apiService.GetPosts(
      this.currentPage,
      this.pageSize,
      {
        CategoryId: parseInt(this.postID),
      }
    );
    this.searchResults = response.data;
    this.totalPages = response.totalCount;
    apiService.ChooseLang();
  },
});
