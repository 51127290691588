import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-47bb9f5b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "featured-data-container" }
const _hoisted_2 = { class: "featured-container" }
const _hoisted_3 = { class: "featured-case" }
const _hoisted_4 = ["onClick", "id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_show_case = _resolveComponent("show-case")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["header-container", _ctx.responseData.className])
    }, [
      _createElementVNode("h1", null, _toDisplayString(_ctx.responseData.header), 1)
    ], 2),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "arrow arrow-left",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.PreviousPost()))
      }, "<"),
      _createElementVNode("div", {
        class: "arrow arrow-right",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.NextPost()))
      }, ">"),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.carouselPosts, (post, i) => {
          return (_openBlock(), _createElementBlock("div", {
            key: i,
            class: _normalizeClass('featured invisible post' + _ctx.responseData.className),
            onClick: ($event: any) => (_ctx.goToPost(post.id)),
            id: _ctx.responseData.className + 'No' + i
          }, [
            _createVNode(_component_show_case, {
              "image-url": post.photo!,
              name: post.personName,
              text: post.content,
              url: post.videoLink!,
              postId: post.id.toString()
            }, null, 8, ["image-url", "name", "text", "url", "postId"])
          ], 10, _hoisted_4))
        }), 128))
      ])
    ])
  ]))
}