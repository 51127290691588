
import { defineComponent, PropType } from "vue";
import ShowCase from "../components/ShowCase.vue";
import { apiService, Post } from "./apiService";

enum FeatureTypes {
  pinned = "recommended",
  latest = "latest",
  random = "random",
}

interface MyData {
  header: string;
  response: {
    imageSrc: string;
    name: string;
    surname: string;
    text: string;
    url: string;
  }[];
  className: string;
}

export default defineComponent({
  data() {
    let responseData: MyData = {
      header: "",
      className: "",
      response: [],
    };
    let postIndex = 0;
    return {
      responseData,
      postIndex,
    };
  },
  props: {
    featureType: {
      type: String,
      required: true,
    },
    carouselPosts: {
      type: Object as PropType<Post[]>,
      required: true,
    },
  },
  components: {
    "show-case": ShowCase,
  },
  mounted() {
    const lang = localStorage.getItem("lang");
    if (lang == "en") {
      if (this.featureType == FeatureTypes.pinned) {
        this.responseData.header = "Pinned";
        // USER INFO END
        this.responseData.className = "container1";
      } else if (this.featureType == FeatureTypes.latest) {
        this.responseData.header = "Latest";
        // USER INFO END
        this.responseData.className = "container2";
      } else {
        this.responseData.header = "Random";
        // USER INFO END
        this.responseData.className = "container3";
      }
    } else {
      if (this.featureType == FeatureTypes.pinned) {
        this.responseData.header = "რეკომენდირებული";
        // USER INFO END
        this.responseData.className = "container1";
      } else if (this.featureType == FeatureTypes.latest) {
        this.responseData.header = "ბოლოს ატვირთული";
        // USER INFO END
        this.responseData.className = "container2";
      } else {
        this.responseData.header = "შემთხვევითი";
        // USER INFO END
        this.responseData.className = "container3";
      }
    }
  },
  updated() {
    // console.log({ posts: this.carouselPosts });
    const featuredPosts = document.querySelectorAll(
      ".post" + this.responseData.className
    );
    featuredPosts.forEach((post) => {
      if (parseInt(post.id.split("No")[1]) == this.postIndex) {
        post.classList.remove("invisible");
      }
    });
  },
  methods: {
    async goToPost(id: number) {
      // await apiService.getPostById(id);
      this.$router.push("/posts/" + id);
    },
    NextPost() {
      const featuredPosts = document.querySelectorAll(
        ".post" + this.responseData.className
      );
      this.postIndex = (this.postIndex + 1) % featuredPosts.length;
      featuredPosts.forEach((post) => {
        if (parseInt(post.id.split("No")[1]) == this.postIndex) {
          post.classList.remove("invisible");
        } else {
          post.classList.add("invisible");
        }
      });
    },
    PreviousPost() {
      const featuredPosts = document.querySelectorAll(
        ".post" + this.responseData.className
      );
      this.postIndex =
        (featuredPosts.length + this.postIndex - 1) % featuredPosts.length;
      featuredPosts.forEach((post) => {
        if (parseInt(post.id.split("No")[1]) == this.postIndex) {
          post.classList.remove("invisible");
        } else {
          post.classList.add("invisible");
        }
      });
    },
  },
});
