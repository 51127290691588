
import { Vue } from "vue-class-component";
import { apiService } from "@/components/apiService";
export default class NavbarComponent extends Vue {
  api = apiService;
  mounted(): void {
    if (localStorage.getItem("lang") == "en") {
      document.getElementById("lang1")?.classList.add("active-lang");
      document.getElementById("lang2")?.classList.remove("active-lang");
    } else {
      document.getElementById("lang1")?.classList.remove("active-lang");
      document.getElementById("lang2")?.classList.add("active-lang");
    }

    document
      .querySelector(".mobile-burger-menu")
      ?.addEventListener("click", () => {
        document
          .querySelector(".mid-nav-container-mobile")!
          .classList.toggle("mid-nav-container-mobile-enabled");
      });

    document.querySelector(".home")?.addEventListener("click", () => {
      document
        .querySelector(".mid-nav-container-mobile")!
        .classList.remove("mid-nav-container-mobile-enabled");
    });

    document.querySelectorAll(".mobile-navbar-button")?.forEach((element) => {
      element.addEventListener("click", () => {
        document
          .querySelector(".mid-nav-container-mobile")!
          .classList.remove("mid-nav-container-mobile-enabled");
      });
    });
  }
}
