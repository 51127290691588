// config.ts

export const BASE_URL = "https://mona.ge/api/";

export const API_TOKEN = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");

  // if (token) {
  //   // You can now use the 'token' variable in your component
  //   console.log("Token:", token);

  //   // Optionally, you can store the token in a Vuex store or a component's data
  //   // this.token = token;
  // }
};
