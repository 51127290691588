import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5cf0c6cf"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "showcase-container" }
const _hoisted_2 = { class: "showcase-title" }
const _hoisted_3 = ["src", "id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", null, _toDisplayString(_ctx.text), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _ctx.imageUrl,
        alt: "Image",
        id: 'showcase-img-' + _ctx.postId
      }, null, 8, _hoisted_3),
      _createElementVNode("h3", null, _toDisplayString(_ctx.name), 1)
    ])
  ]))
}